import { computed, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { capitalize } from 'lodash';
import { LOCALIZATION_STORE_ID } from '@/constants/storeIds';
import { fetchTranslationsService, updateTranslationService, synchronizeTranslationService } from '@/services/application-service/localizationRequests';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { updateModuleService } from '@/services/application-service/moduleRequests';

export const useLocalizationStore = defineStore(LOCALIZATION_STORE_ID, () => {
    const moduleStore = useModuleStore();
    
    /** @type {import('./types/localizationStore').Translations} */
    const translations = ref([]);

    const localizationSettings = computed(() => moduleStore.moduleDetails?.settings?.localization || {});

    const supportedLanguages = computed(() => localizationSettings.value?.supportedLanguages || []);

    const moduleTranslations = computed(() => {
        /** @type {import('./types/localizationStore').IModuleTranslation[]} */
        let keys = [];
        if (moduleStore.variables?.length && translations.value?.length) {
            keys = translations.value.map(translation => {
                let translationName = '';
                const matchedVariable = moduleStore.variables.find(variable => translation.reference.includes(variable.reference));
                const fieldPart = translation.reference.split('_').at(-1);
                if (matchedVariable && fieldPart) {
                    translationName = `${matchedVariable.name} > ${capitalize(fieldPart)}`; 
                }
                return {
                    ...translation,
                    name: translationName
                };
            });
            keys = keys.filter(key => key.name);
        }
        return keys;
    }); 

    const fetchTranslations = async (appId, moduleId) => {
        try {
            let { data: { data } } = await fetchTranslationsService(appId, moduleId);
            translations.value = data;
        } catch (err) {
            console.error(err);
        }
    };

    const updateTranslation = async (appId, moduleId, translationId, payload, params) => {
        try {
            await updateTranslationService(appId, moduleId, translationId, payload, params);
            const updatedTranslation = translations.value.find(translation => translation.id === translationId);
            if (updatedTranslation) {
                updatedTranslation.translations[params.lang_id] = payload.translation;
            }
        } catch (err) {
            console.error(err);
        }
    };

    const synchronizeTranslation = async (appId, moduleId) => {
        try {
            await synchronizeTranslationService(appId, moduleId);
            await Promise.all([
                moduleStore.fetchModule(appId, moduleId),
                fetchTranslations(appId, moduleId)
            ]);
        } catch (err) {
            console.error(err);
            throw new Error('Translations synchronization failed');
        }
    };

    const updateLocalizationSettings = async (appId, moduleId, updatedLocalizationSettings) => {
        await updateModuleService(appId, moduleId, {
            settings: {
                ...moduleStore.moduleDetails.settings,
                localization: updatedLocalizationSettings
            }
        });
    };

    return {
        translations,
        moduleTranslations,
        localizationSettings,
        supportedLanguages,
        fetchTranslations,
        updateTranslation,
        synchronizeTranslation,
        updateLocalizationSettings
    };
});
