// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_RESOURCE_PATH, APPLICATION_SERVICE_ENDPOINT, SYNC_TRANSLATION_RESOURCE_PATH, TRANSLATION_RESOURCE_PATH } from './applicationServiceConstants';
import { moduleServiceConfig } from './moduleRequests';

export const translationServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     * @param {string} [translationId]
     */
    getTranslationsResourcePath: (applicationId, moduleId, translationId = '') => { 
        let translationEndpoint = `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${TRANSLATION_RESOURCE_PATH}`;
        if (translationId) {
            translationEndpoint += `/${translationId}`;
        }
        return translationEndpoint;
    },
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     */
    getSyncTranslationsResourcePath: (applicationId, moduleId) => `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${SYNC_TRANSLATION_RESOURCE_PATH}`
};

translationServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {import('@/services/application-service/applicationServiceTypes').IFetchTranslationsParams} [params]
 */
export const fetchTranslationsService = (applicationId, moduleId, params = {}) => translationServiceConfig.http({
    method: 'GET',
    url: translationServiceConfig.getTranslationsResourcePath(applicationId, moduleId),
    params
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {import('@/services/application-service/applicationServiceTypes').IFetchTranslationsParams} [params]
 */
export const updateTranslationService = (applicationId, moduleId, translationId, data, params = {}) => translationServiceConfig.http({
    method: 'PUT',
    url: translationServiceConfig.getTranslationsResourcePath(applicationId, moduleId, translationId),
    data,
    params
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 */
export const synchronizeTranslationService = (applicationId, moduleId) => translationServiceConfig.http({
    method: 'POST',
    url: translationServiceConfig.getSyncTranslationsResourcePath(applicationId, moduleId)
});
