<template>
  <fieldset>
    <div class="is-flex is-justify-content-flex-end">
      <b-switch v-model="language.isDisabled">
        Disable
      </b-switch>
    </div>
    <b-field label="Label">
      <b-input
        v-model="language.label"
        placeholder="Enter label"
      />
    </b-field>
    <b-field label="Query Param">
      <b-input
        v-model="language.queryParam"
        placeholder="Enter query param"
      />
    </b-field>
    <b-field label="ISO Code">
      <b-autocomplete
        v-model="language.isoCode"
        :data="filteredIsoCodes"
        placeholder="Search ISO code"
        @input="filterIsoCodes"
      />
    </b-field>
  </fieldset>
</template>

<script >
import { ref, watch } from '@vue/composition-api';
import localeCodes from 'locale-codes';
import { generateLangauge, useLanguageManager } from './languageManager';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    default: () => {}
  },
  mode: {
    type: String,
    default: 'ADD'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    language
  } = useLanguageManager();
  watch(() => props.value, () => {
    if (props.mode === 'EDIT' && props.value && Object.keys(props.value).length) {
      language.value = {
        ...props.value
      };
    } else {
      language.value = generateLangauge();
    }
  }, {
    immediate: true
  });
  const isoCodes = localeCodes.all.map(locale => locale.tag);
  const filteredIsoCodes = ref(isoCodes);
  const filterIsoCodes = query => {
    filteredIsoCodes.value = isoCodes.filter(code => code.toLowerCase().includes(query.toLowerCase()));
  };
  return {
    language,
    filteredIsoCodes,
    filterIsoCodes
  };
};
export default __sfc_main;
</script>
