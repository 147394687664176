<template>
  <LocalizationManager />
</template>

<script >
// libs
import { onMounted } from '@vue/composition-api';
// components
import LocalizationManager from '@/modules/builder/components/localization-manager/LocalizationManager.vue';
// stores
import { useLocalizationStore } from '@/modules/builder/store/localizationStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// services
import { updateModuleService } from '@/services/application-service/moduleRequests';
// composables
import { useRoute } from '@/hooks/vueRouter';
// misc
import { generateLocalizationSettings } from '@/modules/builder/components/localization-manager/localizationManager';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const localizationStore = useLocalizationStore();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const loadTranslations = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await localizationStore.fetchTranslations(appId, moduleId);
    } catch (err) {
      console.error(err);
    }
  };
  const initLocalizationSettings = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      if (!moduleStore.moduleDetails?.settings?.localization) {
        const localizationSettings = generateLocalizationSettings();
        await updateModuleService(appId, moduleId, {
          settings: localizationSettings
        });
        moduleStore.moduleDetails.settings = {
          ...moduleStore.moduleDetails.settings,
          ...localizationSettings
        };
      }
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(async () => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.moduleVariablesToRetrieve = [moduleId];
    await Promise.all([moduleStore.fetchModule(appId, moduleId), moduleStore.fetchVariables(appId)]);
    await initLocalizationSettings();
    loadTranslations();
  });
  return {};
};
__sfc_main.components = Object.assign({
  LocalizationManager
}, __sfc_main.components);
export default __sfc_main;
</script>
