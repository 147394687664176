<template>
  <BaseModal
    v-model="isActive"
    :has-modal-card="true"
    :trap-focus="true"
  >
    <CardPopup
      :title="(mode === 'ADD' ? 'Add' : 'Edit') + ' Language'"
    >
      <template #body>
        <LanguageManager 
          :mode="mode"
          :value="selectedLanguage"
        />
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="setModalVisibilty(false)"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            @click="updateLanguage()"
          >
            {{ mode === 'ADD' ? 'Create' : 'Update' }}
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>
  
<script >
// libs
import { computed } from '@vue/composition-api';
import { uuid } from 'vue-uuid';
import { cloneDeep } from 'lodash';
// components
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import LanguageManager from './LanguageManager.vue';
// stores
import { useLocalizationStore } from '@/modules/builder/store/localizationStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useLanguageManager } from './languageManager';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: 'ADD'
  },
  selectedLanguage: {
    type: Object,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const route = useRoute();
  const {
    language
  } = useLanguageManager();
  const localizationStore = useLocalizationStore();
  const isActive = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const setModalVisibilty = visibility => {
    isActive.value = visibility;
  };
  const updateLanguage = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      let updatedLanguages = cloneDeep(localizationStore.supportedLanguages);
      if (props.mode === 'ADD') {
        updatedLanguages.push({
          id: uuid.v4(),
          ...language.value
        });
      } else {
        updatedLanguages = updatedLanguages.map(lang => lang.id === language.value.id ? language.value : lang);
      }
      const payload = {
        ...localizationStore.localizationSettings,
        supportedLanguages: updatedLanguages
      };
      await localizationStore.updateLocalizationSettings(appId, moduleId, payload);
      emit('update');
      setModalVisibilty(false);
    } catch (err) {
      console.error(err);
    }
  };
  return {
    isActive,
    setModalVisibilty,
    updateLanguage
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  LanguageManager
}, __sfc_main.components);
export default __sfc_main;
</script>
